import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAYbm3Ppy-7wSgBx5ePSE6KcBwAqb_Cgy0",
  authDomain: "zjet-fd42f.firebaseapp.com",
  databaseURL: "https://zjet-fd42f.firebaseio.com",
  projectId: "zjet-fd42f",
  storageBucket: "zjet-fd42f.appspot.com",
  messagingSenderId: "399939632839",
  appId: "1:399939632839:web:b85fd1f11ad9f89fde88e2",
};

const firebase = initializeApp(firebaseConfig);

export const analytics = getAnalytics(firebase);
const auth = getAuth();

signInAnonymously(auth)
  .then(() => {})
  .catch((error) => {});

export const db = initializeFirestore(firebase, {
  experimentalForceLongPolling: true,
  useFetchStreams: false,
});
export default firebase;
