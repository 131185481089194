import React, { useEffect, useState } from "react";
import logo from "./assets/logo.svg";
import "./assets/App.scss";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
    limit,
    addDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { FaRegClone } from "react-icons/fa";
import copy from "clipboard-copy";
import moment from "moment";

toast.configure();

const LOOKBACK_TIME = 0.5 * 60 * 60 * 1000; // half hour

function App() {
    const [didRecheck, setDidRecheck] = useState(0);
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");

    const notify = () => {
        toast.error("Error Occured! Try refreshing page", {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    const notifyErrorSubscribe = () => {
        toast.error("Error Occured!", {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    const copied = () => {
        toast.success("Copied to Clipboard", {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    const subscribed = () => {
        toast.success("Subscribed!", {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    const notifyIdiotTypedInWrongEmail = () => {
        toast.error(
            "Enter your own personal email here, not the movie account info",
            {
                position: toast.POSITION.TOP_LEFT,
            }
        );
    };

    const refresh = () => {
        const q = query(
            collection(db, "tokens"),
            orderBy("created", "desc"),
            limit(3)
        );
        return new Promise((resolve, reject) => {
            onSnapshot(
                q,
                (querySnapshot) => {
                    const docs = querySnapshot.docs;
                    if (docs && docs.length) {
                        const doc = docs.find((d) => {
                            const date = moment(Date.now())
                                .utc()
                                .toDate()
                                .getTime();
                            const t = d.get("token");
                            const created_millis = d.get("created");

                            return (
                                (typeof t === "string" ||
                                    typeof t === "number") &&
                                created_millis !== 0 &&
                                date - created_millis < LOOKBACK_TIME // half hour
                            );
                        });

                        if (doc && Number(doc.get("token"))) {
                            resolve(Number(doc.get("token")));
                            return;
                        }

                        resolve(null);
                    }
                },
                (error) => {
                    console.error(error);
                    notify();
                    reject(error);
                }
            );
        });
    };

    const handleSubmitEmail = async (e) => {
        e.preventDefault();
        try {
            const formattedEmail = email.trim().toLowerCase();
            if (
                formattedEmail === "psumovies@gmail.com" ||
                formattedEmail === "firejamesfranklin"
            ) {
                notifyIdiotTypedInWrongEmail();
            } else {
                await addDoc(collection(db, "emails"), {
                    email: email,
                });
                subscribed();
            }
            setEmail("");
        } catch (err) {
            notifyErrorSubscribe(err);
        }
    };

    const onRecheck = async () => {
        setDidRecheck((r) => r + 1);
        refresh()
            .then((code) => {
                setCode(code || "No recent code found");
            })
            .catch(() => {
                notify();
            });
    };

    useEffect(() => {
        refresh()
            .then((code) => {
                setCode(code || "No recent code found");
            })
            .catch(() => {
                notify();
            });
        // eslint-disable-next-line
    }, []);

    const codeStr = code.toString();

    return (
        <IconContext.Provider
            value={{
                color: "orange",
                className: "global-class-name",
                size: "0.5em",
                style: { verticalAlign: "middle" },
            }}
        >
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <h1
                        onClick={() => {
                            if (codeStr.length === 6) {
                                copy(codeStr);
                                copied();
                            }
                        }}
                        className="token"
                    >
                        {codeStr}
                        {codeStr.length === 6 && (
                            <FaRegClone className="icon" />
                        )}
                    </h1>
                    {didRecheck > 2 && codeStr === "No recent code found" && (
                        <p style={{ fontSize: "0.5em", color: "grey" }}>
                            If the code isn't showing up, try resending the
                            email{" "}
                        </p>
                    )}
                    <div
                        href="/"
                        onClick={onRecheck}
                        className="fancy-button pop-onhover bg-gradient1 no-select"
                    >
                        <span>Recheck for Token</span>
                    </div>
                    <form onSubmit={handleSubmitEmail}>
                        <div className="container">
                            <p style={{ fontSize: 10 }}>
                                Want to know when the password changes?
                            </p>
                            <div class="webflow-style-input">
                                <input
                                    class=""
                                    type="email"
                                    value={email}
                                    placeholder="Enter your email"
                                    onChange={(e) => setEmail(e.target.value)}
                                ></input>
                                <button
                                    type="submit"
                                    onClick={handleSubmitEmail}
                                >
                                    <svg
                                        viewBox="0 0 448 512"
                                        width="25"
                                        title="arrow-right"
                                    >
                                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </header>
            </div>
        </IconContext.Provider>
    );
}

export default App;
